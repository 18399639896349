import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// guards
// import MetamaskGuard from 'src/guards/MetamaskGuard';
// import AuthGuard from 'src/guards/AuthGuard';
// layouts
import MainLayout from '../layouts/main';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
// import { PATH_AFTER_LOGIN } from 'src/config';
// import { UserProvider } from 'src/contexts/UserContext';
// import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";

// ----------------------------------------------------------------------

// const apolloClient = new ApolloClient({
//     uri: "https://api.thegraph.com/subgraphs/name/traderjoe-xyz/exchange",
//     cache: new InMemoryCache()
// });

// eslint-disable-next-line react-hooks/rules-of-hooks
const Loadable = (Component: ElementType) => (props: any) => (
    <Suspense fallback={<LoadingScreen isDashboard={false} />}>
        <Component {...props} />
    </Suspense>
);

export default function Router() {
    return useRoutes([
        // Dashboard Routes
        // {
        //     path: "dashboard",
        //     element: (
        //         <ApolloProvider client={apolloClient}>
        //             <MetamaskGuard>
        //                 <UserProvider>
        //                     <AuthGuard>
        //                         <MainLayout />
        //                     </AuthGuard>
        //                 </UserProvider>
        //             </MetamaskGuard>
        //         </ApolloProvider>
        //     ),
        //     children: [
        //         { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        //         { path: "app", element: <Dashboard /> },
        //     ]
        // },

        // Redirect Routes
        {
            path: "redirect",
            element: <Redirect />
        },

        // Main Routes
        {
            path: '*',
            element: <LogoOnlyLayout />,
            children: [
                { path: '500', element: <Page500 /> },
                { path: '404', element: <NotFound /> },
                { path: '*', element: <Navigate to="/404" replace /> },
            ],
        },
        {
            path: '/',
            element: <MainLayout />,
            children: [
                { element: <HomePage />, index: true },
                // { path: "presale", element: <MetamaskGuard><Presale /></MetamaskGuard> }
            ],
        },
        { path: '*', element: <Navigate to="/404" replace /> },
    ]);
}

// IMPORT COMPONENTS
// Main
// const Presale = Loadable(lazy(() => import('../pages/Presale')));
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

// Dashboard
// const Dashboard = Loadable(lazy(() => import('../pages/Dashboard')));

// Redirect
const Redirect = Loadable(lazy(() => import('../pages/Redirect')));