import { Box, Button, Tooltip } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { MetamaskContext } from "src/contexts/MetamaskContext";
import { PATH_DASHBOARD } from "src/routes/paths";
import { fShortenAddress } from "src/utils/formatString";

type Props = {
    disabled: boolean;
}

export default function ConnectedWallet({ disabled = false }: Props) {
    const navigate = useNavigate();
    const isEthereum = useContext(MetamaskContext);
    const { ethereum } = (window as any);
    const [account, setAccount] = useState("");

    const handleAccountsChanged = (accounts: string[]) => {
        if (accounts.length === 0) {
            setAccount("");
        } else {
            setAccount(accounts[0]);
        }
    };

    useEffect(() => {
        if (isEthereum) {
            ethereum.on("accountsChanged", handleAccountsChanged);

            if (ethereum.selectedAddress)
                setAccount(ethereum.selectedAddress);

            return function cleanup() {
                ethereum.removeListener("accountsChanged", handleAccountsChanged);
                setAccount("");
            };
        }
    }, [isEthereum, ethereum]);

    const handleConnect = async () => {
        const a = await ethereum.request({ method: "eth_requestAccounts" });
        setAccount(a[0]);
    };

    return (
        <Box>
            {account !== "" ?
                <Tooltip title={account} arrow placement="bottom">
                    <Button variant="outlined" size="large" disabled={disabled} onClick={() => navigate(PATH_DASHBOARD.general.app, { replace: true })}>{fShortenAddress(account)}</Button>
                </Tooltip>
                :
                <Button variant="outlined" size="large" disabled={disabled} onClick={handleConnect}>CONNECT WALLET</Button>
            }
        </Box>
    );
}