import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
    disabledLink?: boolean;
    imageSource: string;
}

export default function HeaderLogo({ disabledLink = false, imageSource, sx }: Props) {
    const logo = (
        <Box component="img" src={imageSource} sx={{ ...sx }} />
    );

    if (disabledLink) {
        return <>{logo}</>;
    }

    return <RouterLink to="/">{logo}</RouterLink>;
}
