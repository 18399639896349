// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
// import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeLocalization from './components/ThemeLocalization';
// google analytics
import ReactGA from "react-ga";
import { useEffect } from 'react';

// ----------------------------------------------------------------------

const TRACKING_ID = "UA-230468967-1";

ReactGA.initialize(TRACKING_ID);

export default function App() {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <ThemeProvider>
            <ThemeColorPresets>
                <ThemeLocalization>
                    <RtlLayout>
                        <NotistackProvider>
                            <ProgressBarStyle />
                            <ChartStyle />
                            <ScrollToTop />
                            <Router />
                        </NotistackProvider>
                    </RtlLayout>
                </ThemeLocalization>
            </ThemeColorPresets>
        </ThemeProvider>
    );
}
