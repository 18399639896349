import { alpha, Box, Collapse, Drawer, LinkProps, List, ListItemButton, ListItemButtonProps, ListItemIcon, ListItemText, styled } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import { IconButtonAnimate } from "src/components/animate";
import Iconify from "src/components/Iconify";
import Logo from "src/components/Logo";
import { NavSectionVertical } from "src/components/nav-section";
import Scrollbar from "src/components/Scrollbar";
import { MenuItemProps, MenuProps } from "./type";

// ----------------------------------------------------------------------

type StyleProps = LinkProps & ListItemButtonProps;

interface ListItemStyleProps extends StyleProps {
    component?: ReactNode;
    to?: string;
    end?: boolean;
}

const ListItemStyle = styled(ListItemButton)<ListItemStyleProps>(({ theme }) => ({
    ...theme.typography.body2,
    height: 48,
    textTransform: 'capitalize',
    color: theme.palette.text.secondary,
}));

export default function MenuMobile({ navConfig }: MenuProps) {
    const { pathname } = useLocation();
    const [open, setOpen] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);

    useEffect(() => {
        if (drawerOpen) {
            handleDrawerClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const handleOpen = () => {
        setOpen(!open);
    };

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    return (
        <>
            <IconButtonAnimate
                onClick={handleDrawerOpen}
                sx={{ ml: 1, }}
            >
                <Iconify icon="eva:menu-2-fill" />
            </IconButtonAnimate>

            <Drawer
                open={drawerOpen}
                onClose={handleDrawerClose}
                ModalProps={{ keepMounted: true }}
                PaperProps={{ sx: { pb: 5, width: 260, backgroundColor: "#2B2F51" } }}
            >
                <Scrollbar>
                    <Logo sx={{ mx: 2.5, my: 3 }} />

                    <List disablePadding>
                        {navConfig.map((link) => (
                            <MenuMobileItem key={link.title} item={link} isOpen={open} onOpen={handleOpen} />
                        ))}
                    </List>
                </Scrollbar>
            </Drawer>
        </>
    );
}

// ----------------------------------------------------------------------

type MenuMobileItemProps = {
    item: MenuItemProps;
    isOpen: boolean;
    onOpen: VoidFunction;
};

function MenuMobileItem({ item, isOpen, onOpen }: MenuMobileItemProps) {
    const { title, path, icon, children, disabled } = item;

    if (children) {
        return (
            <>
                <ListItemStyle
                    onClick={onOpen}
                    sx={{ color: "secondary.main" }}
                >
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText disableTypography primary={title} />
                    <Iconify
                        icon={isOpen ? "eva:arrow-up-fill" : "eva:arrow-down-fill"}
                        sx={{ width: 16, height: 16, ml: 1 }}
                    />
                </ListItemStyle>

                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                    <Box sx={{ display: "flex", flexDirection: "column-reverse" }}>
                        <NavSectionVertical navConfig={children} />
                    </Box>
                </Collapse>
            </>
        );
    }

    return (
        <ListItemStyle
            to={path}
            component={RouterLink}
            end={path === "/"}
            disabled={disabled}
            sx={{
                color: "secondary.main",
                "&.active": {
                    color: "primary.main",
                    fontWeight: "fontWeightMedium",
                    bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
                },
            }}
        >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText disableTypography primary={title} />
        </ListItemStyle>
    );
}