import { createContext, ReactNode } from "react";

const MetamaskContext = createContext(false);

type Props = {
    children: ReactNode;
}

function MetamaskProvider({ children }: Props) {
    const { ethereum } = (window as any);

    const isEthereum = !(ethereum === undefined || !ethereum);

    return (
        <MetamaskContext.Provider
            value={isEthereum}
        >
            {children}
        </MetamaskContext.Provider>
    )
}

export { MetamaskProvider, MetamaskContext };