import { AppBar, Stack, Toolbar } from "@mui/material";
import HeaderLogo from "src/components/HeaderLogo";
import useResponsive from "src/hooks/useResponsive";
// import CivitasPrice from "src/sections/navigation/CivitasPrice";
import ConnectedWallet from "src/sections/navigation/ConnectedWallet";
import menuConfig from "./MenuConfig";
import MenuDesktop from "./MenuDesktop";
import MenuMobile from "./MenuMobile";

export default function Header() {
    const isDesktop = useResponsive("up", "lg");

    return (
        <AppBar sx={{ width: 1, py: 1, backgroundColor: "#161932" }} elevation={0}>
            <Toolbar>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: 1, mx: isDesktop ? 8 : 0 }}>
                    {isDesktop &&
                        <>
                            <HeaderLogo imageSource="/logo/logo_bg.png" sx={{ maxWidth: 160 }} />

                            <MenuDesktop navConfig={menuConfig} />
                        </>
                    }

                    {!isDesktop && <MenuMobile navConfig={menuConfig} />}

                    <Stack direction="row" spacing={3}>
                        {/* <CivitasPrice /> */}

                        <ConnectedWallet disabled />
                    </Stack>
                </Stack>
            </Toolbar>
        </AppBar>
    );
}