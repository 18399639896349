import { Box, styled } from "@mui/material";
import { Outlet } from "react-router";
import { HEADER } from "src/config";
import Header from "./header";

const MainStyle = styled('main')(({ theme }) => ({
    flexGrow: 1,
    paddingTop: HEADER.MOBILE_HEIGHT + 24,
    paddingBottom: HEADER.MOBILE_HEIGHT + 24,
    [theme.breakpoints.up('lg')]: {
        paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
        paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
        width: `calc(100%)`,
        transition: theme.transitions.create('margin-left', {
            duration: theme.transitions.duration.shorter,
        }),
    },
}));

export default function MainLayout() {
    return (
        <Box sx={{ display: { lg: "flex" }, minHeight: { lg: 1 } }}>
            <Header />

            <MainStyle>
                <Outlet />
            </MainStyle>
        </Box>
    );
}