import { Box, Grid, Link, LinkProps, List, ListItem, ListSubheader, Popover, Stack, styled } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { NavLink as RouterLink } from 'react-router-dom';
import Iconify from "src/components/Iconify";
import useAnalyticsEventTracker from "src/hooks/useAnalyticsEventTracker";
import NavigationLink from "src/sections/navigation/NavigationLink";
import { MenuItemProps, MenuProps } from "./type";

interface RouterLinkProps extends LinkProps {
    component?: ReactNode;
    to?: string;
    end?: boolean;
}

const ListItemStyle = styled(ListItem)<RouterLinkProps>(({ theme }) => ({
    ...theme.typography.body2,
    padding: 0,
    marginTop: theme.spacing(2),
    color: theme.palette.text.secondary,
    transition: theme.transitions.create('color'),
    '&:hover': {
        color: theme.palette.text.primary,
    },
}));

export default function MenuDesktop({ navConfig }: MenuProps) {
    const { pathname } = useLocation();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (open) {
            handleClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={3}>
            {navConfig.map((link) =>
                <MenuDesktopItem
                    key={link.title}
                    item={link}
                    isOpen={open}
                    onOpen={handleOpen}
                    onClose={handleClose}
                />
            )}
        </Stack>
    );
}

// ----------------------------------------------------------------------

function IconBullet() {
    return (
        <Box sx={{ width: 24, height: 16, display: 'flex', alignItems: 'center' }}>
            <Iconify
                width={16}
                height={16}
                icon={"eva:arrow-right-fill"}
                sx={{
                    ml: "2px",
                    color: "currentcolor"
                }}
            />
        </Box>
    );
}

// ----------------------------------------------------------------------

type MenuDesktopItemProps = {
    item: MenuItemProps;
    isOpen: boolean;
    onOpen: VoidFunction;
    onClose: VoidFunction;
};

function MenuDesktopItem({
    item,
    isOpen,
    onOpen,
    onClose
}: MenuDesktopItemProps) {
    const { title, path, disabled, children } = item;
    const gaEventTracker = useAnalyticsEventTracker('Resources (Desktop)');

    if (children) {
        return (
            <>
                <Link
                    onClick={disabled ? () => { } : onOpen}
                    fontSize={24}
                    color={disabled ? "#9CAFFF55" : "#9CAFFF"}
                    underline="none"
                    sx={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        ...(isOpen && { opacity: 0.72 }),
                    }}
                >
                    {title}
                    <Iconify
                        icon={isOpen ? "eva:arrow-up-fill" : "eva:arrow-down-fill"}
                        sx={{ ml: 0.5, width: 16, height: 16 }}
                    />
                </Link>

                {!disabled &&
                    <Popover
                        open={isOpen}
                        anchorReference="anchorPosition"
                        anchorPosition={{ top: 80, left: 0 }}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                        onClose={onClose}
                        PaperProps={{
                            sx: {
                                p: 5,
                                right: 16,
                                m: "auto",
                                borderRadius: 2,
                                backgroundColor: "#2B2F51",
                                maxWidth: (theme) => theme.breakpoints.values.sm,
                                boxShadow: (theme) => theme.customShadows.z24,
                            },
                        }}
                    >
                        <Grid container spacing={3}>
                            {children.map((list) => {
                                const { subheader, items } = list;

                                return (
                                    <Grid key={subheader} item xs={12} md={6}>
                                        <List disablePadding>
                                            <ListSubheader
                                                disableSticky
                                                disableGutters
                                                sx={{
                                                    display: "flex",
                                                    lineHeight: "unset",
                                                    alignItems: "center",
                                                    color: "text.primary",
                                                    typography: "h4",
                                                    fontWeight: "400"
                                                }}
                                            >
                                                {subheader}
                                            </ListSubheader>

                                            {items.map((item) => (
                                                <ListItemStyle
                                                    key={item.title}
                                                    to={item.path}
                                                    component={RouterLink}
                                                    underline="none"
                                                    onClick={() => { gaEventTracker(item.title); console.log(item.title); }}
                                                    sx={{
                                                        typography: "subtitle1",
                                                        fontWeight: "400",
                                                        "&.active": {
                                                            color: "text.primary",
                                                            typography: "subtitle1",
                                                        },
                                                    }}
                                                >
                                                    <IconBullet />
                                                    {item.title}
                                                </ListItemStyle>
                                            ))}
                                        </List>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Popover>
                }
            </>
        );
    }

    return (
        <NavigationLink
            text={title}
            nav={path}
            disabled={disabled}
        />
    );
}