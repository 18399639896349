// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
    return `${root}${sublink}`;
}

export const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_REDIRECT = {
    whitepaper: "/redirect?ref=whitepaper",
    discord: "/redirect?ref=discord",
    twitter: "/redirect?ref=twitter",
};

export const PATH_PAGE = {
    presale: "/presale",
    page404: '/404',
    page500: '/500',
};

export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    general: {
        app: path(ROOTS_DASHBOARD, "/app"),
    },
};