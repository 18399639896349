import Iconify from "src/components/Iconify";
import { PATH_DASHBOARD, PATH_PAGE, PATH_REDIRECT } from "src/routes/paths";

// ----------------------------------------------------------------------

const ICON_SIZE = {
    width: 22,
    height: 22,
};

const menuConfig = [
    {
        title: "Home",
        icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
        path: "/",
        disabled: false,
    },
    {
        title: "Presale",
        icon: <Iconify icon={'eva:star-fill'} {...ICON_SIZE} />,
        path: PATH_PAGE.presale,
        disabled: true,
    },
    {
        title: "Dashboard",
        icon: <Iconify icon={"eva:pie-chart-fill"} {...ICON_SIZE} />,
        path: PATH_DASHBOARD.general.app,
        disabled: true,
    },
    {
        title: "Stake Lock",
        icon: <Iconify icon={"eva:lock-fill"} {...ICON_SIZE} />,
        path: PATH_PAGE.page404,
        disabled: true,
    },
    {
        title: "Resources",
        icon: <Iconify icon={'eva:file-fill'} {...ICON_SIZE} />,
        path: "/resources",
        disabled: false,
        children: [
            {
                subheader: "Other",
                items: [
                    { title: "Incubator", path: PATH_PAGE.page404 },
                    { title: "Launchpad", path: PATH_PAGE.page404 },
                    { title: "Lottery", path: PATH_PAGE.page404 },
                    { title: "NFT & DAO", path: PATH_PAGE.page404 },
                ],
            },
            {
                subheader: "Quick Links",
                items: [
                    { title: "Whitepaper", path: PATH_REDIRECT.whitepaper },
                    { title: "Discord", path: PATH_REDIRECT.discord },
                    { title: "Twitter", path: PATH_REDIRECT.twitter },
                ],
            },
        ],
    },
];

export default menuConfig;