import { Link, Tooltip } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import { getActive } from "src/components/nav-section";

type Props = {
    text: string;
    nav: string;
    disabled?: boolean;
    onClick?: VoidFunction | null;
}

export default function NavigationLink({ text, nav, disabled = false, onClick = null }: Props) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const active = getActive(nav, pathname);

    const handleNavigate = () => {
        if (!disabled)
            navigate(nav, { replace: true });
    }

    return (
        <>
            {disabled ?
                <Tooltip title="Coming soon" placement="bottom">
                    <Link fontSize={24} color={"#9CAFFF55"} underline="none">{text}</Link>
                </Tooltip>
                :
                <Link fontSize={24} onClick={onClick !== null ? onClick : handleNavigate} color={active ? "#8450A2" : "#9CAFFF"} underline="none" sx={{ cursor: "pointer" }}>{text}</Link>
            }
        </>
    );
}